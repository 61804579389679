// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const chatIconsIds = {
	admin: 1,
	sir_artisto_horsicus_masteri_the_third: 2,
	creator: 3,
	dev_wizard_hat: 4,
	moderator: 5,
	coin: 6,
	money_bag: 7,
	sapphire: 8,
	emerald: 9,
	ruby: 10,
	diamond: 11,
	glorious_crown: 12,
	diamond_crown: 13,
	infernal_crown: 14,
	prismatic_crown: 15,
	silver_bug_finder: 20,
	gold_bug_finder: 21,
	diamond_bug_finder: 22,
	super_moderator: 23,
	bronze_star: 25,
	silver_star: 26,
	gold_star: 27,
	diamond_star: 28,
	distinguished_community_member: 29,
	friendly: 30,
	socialite: 31,
	lorekeeper: 32,
	keeper_of_legends: 33,
	shrimp_lord: 50,
	birthday_cake: 60,
	doodlesaurus: 70,
	chat_moderator: 71,
	idlescape_plus: 100,
	discord: 150,
	moldy_ordew: 200,
	mini_moldy_ordew: 201,
	l_e_m_o_n: 202,
	mini_lemon: 203,
	the_holy_cheese_wedge: 204,
	notsoholy_cheese_wedge: 205,
	angry_stump: 206,
	ancient_fisherman: 207,
	coin_gang: 208,
	gameboyatron: 209,
	o_t_i_s: 210,
	dog_with_a_temper: 211,
	flying_purple_carrot: 212,
	karateswords: 213,
	shrimp_gods_prophet: 214,
	shrimp_gods_disciple: 215,
	true_blue: 216,
	top_3_total_level: 500,
	top_3_total_mastery_level: 501,
	top_3_mining_level: 502,
	top_3_mining_mastery_level: 503,
	top_3_foraging_level: 504,
	top_3_foraging_mastery_level: 505,
	top_3_fishing_level: 506,
	top_3_fishing_mastery_level: 507,
	top_3_farming_level: 508,
	top_3_farming_mastery_level: 509,
	top_3_enchanting_level: 510,
	top_3_enchanting_mastery_level: 511,
	top_3_runecrafting_level: 512,
	top_3_runecrafting_mastery_level: 513,
	top_3_smithing_level: 514,
	top_3_smithing_mastery_level: 515,
	top_3_crafting_level: 516,
	top_3_crafting_mastery_level: 517,
	top_3_cooking_level: 518,
	top_3_cooking_mastery_level: 519,
	top_3_constitution_level: 520,
	top_3_constitution_mastery_level: 521,
	top_3_attack_level: 522,
	top_3_attack_mastery_level: 523,
	top_3_strength_level: 524,
	top_3_strength_mastery_level: 525,
	top_3_defense_level: 526,
	top_3_defense_mastery_level: 527,
	top_3_magic_level: 528,
	top_3_magic_mastery_level: 529,
	top_3_range_level: 530,
	top_3_range_mastery_level: 531,
	top_weekly_altar_donator: 532,
	top_3_global_shrine_donator: 533,
	goblin_settlement_master: 600,
	dark_fortress_master: 601,
	ancient_barrows_master: 602,
	palace_of_flame_master: 603,
	eye_of_the_storm_master: 604,
	aberrant_shrimp_master: 605,
	giants_keep_master: 606,
	elder_ruins_master: 607,
	overgrown_castle_master: 608,
	master_of_the_maelstrom: 609,
	nature_atheneum_master: 610,
	abandoned_academy_master: 611,
	dwarven_research_master: 612,
	chicken_kitchen_master: 613,
	dark_vault_master: 614,
	master_of_the_void: 615,
	elite_scroll_enthusiast: 1000,
	combat_chad: 1001,
	burnt_food_connoisseur: 1002,
	marine_geologist: 1003,
	promise_of_the_prismatic: 1004,
	le_toucan_has_arrived: 1005,
	to_the_moon: 1006,
	court_jester: 1007,
	wiki_madness_participant: 2000,
	wiki_madness_bronze: 2001,
	wiki_madness_silver: 2002,
	wiki_madness_gold: 2003,
	buff_madness_participant: 2004,
	prelaunch_participant: 2005,
};
