import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { socket } from '../../../../services/socket.service';
import { IDialogProps } from './MainItemDialog';

export function ChangeUsernameTokenDialog(props: IDialogProps) {
	const [tokenInput, setTokenInput] = useState('');

	function handleTokenInput() {
		socket.emit('consumable:token', {
			inventoryItemID: props.item.id,
			optionalInput: tokenInput,
		});
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Username Change</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						Make sure to double check your intended name! You will need another token in order to change it
						again.
					</Text>
					<Text>Your username will NOT be held for you! You give it up the moment your name is changed.</Text>
					<Text>
						If you input a name that you already own on another character, the names will be swapped.
					</Text>
					<IdlescapeInput
						value={tokenInput}
						contentEditable={true}
						variant='simple'
						setValue={setTokenInput}
						className='chat-input-field'
						type='text'
						placeholder='NEW USERNAME HERE'
						autoComplete='off'
						width='250px'
						maxLength={24}
					/>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleTokenInput}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export function RenameItemTokenDialog(props: IDialogProps) {
	const [tokenInput, setTokenInput] = useState('');

	function handleTokenInput() {
		if (!props.renameTokenId) return;
		socket.emit('consumable:token', {
			inventoryItemID: props.renameTokenId,
			optionalInput: tokenInput,
			optionalTargetItemDB: props.item.id,
		});
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Itemname Change</ModalHeader>
				<ModalBody>
					<Text>
						Make sure to double check your intended name! You will need another token in order to change it
						again.
					</Text>
					<Text>Your username will NOT be held for you! You give it up the moment your name is changed.</Text>
					<Text>
						If you input a name that you already own on another character, the names will be swapped.
					</Text>
					<IdlescapeInput
						value={tokenInput}
						contentEditable={true}
						variant='simple'
						setValue={setTokenInput}
						className='chat-input-field'
						type='text'
						placeholder='NEW ITEMNAME HERE'
						autoComplete='off'
						width='250px'
						maxLength={24}
					/>
				</ModalBody>
				<ModalFooter className='item-dialogue-button-div'>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleTokenInput}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export function RespecTokenDialog(props: IDialogProps) {
	// No input or anything, just an ARE YOU SURE? with the button to confirm
	function handleToken() {
		socket.emit('talent:clearTalents');
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Respec Token</ModalHeader>
				<ModalBody>
					<Text>
						Are you sure you want to respec your talents? This will reset all of your talents and refund
						your talent points.
					</Text>
					<Text>Untradeable Respec Tokens will be consumed first, followed by tradeable Respec Tokens.</Text>
				</ModalBody>
				<ModalFooter className='item-dialogue-button-div'>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleToken}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
